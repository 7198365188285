var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"message-pc"}},[_c('AppContainer',{attrs:{"useBackground":true,"useShadow":true,"hasHeader":false}},[_c('div',{staticClass:"message-pc-body"},[_c('div',{staticClass:"content-scroll",attrs:{"id":"content-scroll"}},[_c('div',{staticClass:"d-flex overflow-hidden"},[_c('div',{staticClass:"ms-layout",style:([_vm.checkNoMsg == 0 ? { height: '2000px' } : ''])},[_c('span',{staticClass:"text-center f-w6 fs-20 title"},[_vm._v("メッセージ一覧")]),_c('div',{staticClass:"filter-message"},[_c('MessageFilter',{on:{"emitType":function($event){return _vm.chooseType($event)}}})],1),(_vm.firebaseListRoom.length > 0 && _vm.checkNoMsg == 1)?_c('hr',{staticClass:"mt-0 mb-0 divide"}):_vm._e(),(_vm.firebaseListRoom.length > 0 && _vm.checkNoMsg == 1)?_c('div',{staticClass:"list-message"},[_vm._l((_vm.firebaseListRoom),function(data,index){return _c('div',{key:index,staticClass:"message-item-cover"},[(
                    !(
                      (data.type === 2 &&
                        data.members &&
                        data.members[0] &&
                        _vm.listUserIdsBlock.includes(data.members[0].id)) ||
                      data.message === '' ||
                      (data.message.type == 11 &&
                        data.message.from != _vm.authUser.sex)
                    )
                  )?_c('MessageListItem',{ref:'room-' + index,refInFor:true,staticClass:"message-item-pc",class:{
                    'message-item--active':
                      _vm.roomId !== null && data.id === _vm.roomId
                  },attrs:{"data":Object.assign({}, data,
                    {members:
                      data.members[0].sex == _vm.authUser.sex && data.members[1]
                        ? [data.members[1]]
                        : [].concat( data.members )}),"propsIsPC":true,"imageSize":"65px"}}):_vm._e(),(
                    index < _vm.firebaseListRoom.length - 1 &&
                      data.message !== '' &&
                      (data.message.type != 11 ||
                        data.message.from == _vm.authUser.sex)
                  )?_c('hr',{staticClass:"mb-0 divide-message"}):_vm._e()],1)}),_c('scroll-loader',{attrs:{"loader-method":_vm.getList,"loader-disable":_vm.disable}})],2):_vm._e(),(_vm.checkNoMsg == 0)?_c('div',{staticClass:"no-member-pc"},[_c('div',{staticClass:"no-member-msg-pc"},[_c('img',{attrs:{"src":require("@/assets/image/bird.svg"),"alt":"boss","width":"57","height":"57"}}),_c('h3',{staticClass:"f-w6"},[_vm._v("新しいメッセージがありません")])])]):_vm._e()]),(_vm.roomId && _vm.roomId !== null)?_c('div',{staticClass:"ms-detail"},[_c('MessageDetail',{attrs:{"propsRoomId":_vm.roomId,"propsIsPC":true}})],1):_vm._e()])]),_c('CardFooter',{ref:"footer",attrs:{"prefer":"message"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }