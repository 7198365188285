<template>
  <div id="message-pc">
    <AppContainer :useBackground="true" :useShadow="true" :hasHeader="false">
      <div class="message-pc-body">
        <div id="content-scroll" class="content-scroll">
          <div class="d-flex overflow-hidden">
            <div
              class="ms-layout"
              :style="[checkNoMsg == 0 ? { height: '2000px' } : '']"
            >
              <span class="text-center f-w6 fs-20 title">メッセージ一覧</span>
              <div class="filter-message">
                <MessageFilter @emitType="chooseType($event)" />
              </div>
              <hr
                class="mt-0 mb-0 divide"
                v-if="firebaseListRoom.length > 0 && checkNoMsg == 1"
              />
              <div
                class="list-message"
                v-if="firebaseListRoom.length > 0 && checkNoMsg == 1"
              >
                <!-- data for message  -->
                <div
                  class="message-item-cover"
                  v-for="(data, index) in firebaseListRoom"
                  :key="index"
                >
                  <MessageListItem
                    v-if="
                      !(
                        (data.type === 2 &&
                          data.members &&
                          data.members[0] &&
                          listUserIdsBlock.includes(data.members[0].id)) ||
                        data.message === '' ||
                        (data.message.type == 11 &&
                          data.message.from != authUser.sex)
                      )
                    "
                    :data="{
                      ...data,
                      members:
                        data.members[0].sex == authUser.sex && data.members[1]
                          ? [data.members[1]]
                          : [...data.members]
                    }"
                    :ref="'room-' + index"
                    :propsIsPC="true"
                    imageSize="65px"
                    :class="{
                      'message-item--active':
                        roomId !== null && data.id === roomId
                    }"
                    class="message-item-pc"
                  />
                  <hr
                    class="mb-0 divide-message"
                    v-if="
                      index < firebaseListRoom.length - 1 &&
                        data.message !== '' &&
                        (data.message.type != 11 ||
                          data.message.from == authUser.sex)
                    "
                  />
                </div>
                <scroll-loader
                  :loader-method="getList"
                  :loader-disable="disable"
                >
                </scroll-loader>
              </div>
              <div class="no-member-pc" v-if="checkNoMsg == 0">
                <div class="no-member-msg-pc">
                  <img
                    src="@/assets/image/bird.svg"
                    alt="boss"
                    width="57"
                    height="57"
                  />
                  <h3 class="f-w6">新しいメッセージがありません</h3>
                </div>
              </div>
            </div>
            <div class="ms-detail" v-if="roomId && roomId !== null">
              <MessageDetail :propsRoomId="roomId" :propsIsPC="true" />
            </div>
          </div>
        </div>
        <CardFooter ref="footer" prefer="message" />
      </div>
    </AppContainer>
  </div>
</template>

<script>
import CardFooter from "@/components/CardFooter.vue";
import MessageFilter from "./MessageFilter.vue";
import MessageListItem from "./MessageListItem.vue";
import MessageDetail from "./MessageDetail.vue";
import { mapActions, mapGetters } from "vuex";
import {
  SYSTEM_ROOM_TYPE,
  INDIVIDUAL_ROOM_TYPE,
  GROUP_ROOM_TYPE
} from "@/constants/message";

export default {
  name: "MessagePC",
  components: {
    MessageDetail,
    MessageFilter,
    MessageListItem,
    CardFooter
  },
  metaInfo() {
    return {
      title: "メッセージ一覧",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      type: [SYSTEM_ROOM_TYPE, INDIVIDUAL_ROOM_TYPE, GROUP_ROOM_TYPE],
      roomId: null,
      disable: false,
      checkNoMsg: null,
      isGetting: false
    };
  },
  watch: {
    "$route.params.id": function(id) {
      this.roomId = id;
      if (!this.roomId) {
        this.setRoomId(null);
      }
    },
    firebaseListRoom() {
      this.isGetting = false;
      if (
        Object.keys(this.firebaseListRoom).length ===
          this.firebaseUnread.length ||
        Object.keys(this.firebaseListRoom).length % 10 !== 0 ||
        Object.keys(this.firebaseListRoom).length < 10
      ) {
        this.disable = true;
      } else {
        this.disable = false;
      }

      setTimeout(() => {
        if (this.firebaseListRoom.length > 0) {
          this.checkNoMsg = 1;
        }
      }, 2200);
    },
    firebaseUnread() {
      if (
        Object.keys(this.firebaseListRoom).length ===
          this.firebaseUnread.length ||
        Object.keys(this.firebaseListRoom).length % 10 !== 0 ||
        Object.keys(this.firebaseListRoom).length < 10
      ) {
        this.disable = true;
      } else {
        this.disable = false;
      }
    }
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
      firebaseListRoom: "firebase/listRoom",
      listUserIdsBlock: "auth/userIdsBlock",
      firebaseUnread: "firebase/unread"
    })
  },
  async created() {
    this.getUserIdsBlock();
    this.$root.$refs.loading.start();
    this.roomId = this.$route.params.id;
    this.getListRoom({
      userId: this.authUser.user_id,
      type: this.type
    }).then(data => {
      setTimeout(() => {
        if (data.length === 0) {
          this.checkNoMsg = 0;
        } else {
          this.checkNoMsg = 1;
        }
      }, 2200);
    });

    this.getUnread({
      userId: this.authUser.user_id
    });
    this.$root.$refs.loading.finish();
  },
  methods: {
    ...mapActions("auth", ["getInfoUser"]),
    ...mapActions("auth", ["getUserIdsBlock"]),
    ...mapActions("firebase", ["setRoomId", "getListRoom", "getUnread"]),

    chooseType(data) {
      this.checkNoMsg = 1;
      this.$root.$refs.loading.start();
      this.type = data;
      this.getListRoom({
        userId: this.authUser.user_id,
        type: this.type
      }).then(data => {
        setTimeout(() => {
          if (data.length === 0) {
            this.checkNoMsg = 0;
          }
        }, 2200);
      });
      this.$root.$refs.loading.finish();
    },
    getList() {
      if (this.firebaseListRoom[this.firebaseListRoom.length - 1]) {
        this.isGetting = true;
        this.getListRoom({
          userId: this.authUser.user_id,
          type: this.type,
          lastRoom: this.firebaseListRoom[this.firebaseListRoom.length - 1].id
        }).then(() => {
          setTimeout(() => {
            if (this.isGetting) {
              this.disable = true;
            }
          }, 3000);
        });
      }
    },
    checkShowMessage(data) {
      if (data.length > 0) {
        let checkRoomNotShow = 0;
        let checkRoomShow = 0;
        data.forEach(room => {
          if (
            (room.type === 2 &&
              room.members &&
              room.members[0] &&
              this.listUserIdsBlock.includes(room.members[0].id)) ||
            room.message === ""
          ) {
            checkRoomNotShow = 1;
          } else {
            checkRoomShow = 1;
          }
        });
        if (checkRoomNotShow === 1 && checkRoomShow === 0) {
          this.checkNoMsg = 0;
        } else {
          this.checkNoMsg = 1;
        }
      } else {
        this.checkNoMsg = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.divide {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 0.5;
}
.divide-message {
  margin-left: 0;
  margin-right: 0;
  margin-top: 10px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 0.5;
}
.content-scroll {
  position: relative;
  z-index: 100;
  overflow-y: hidden;
  overflow-x: hidden;
  height: calc(100vh - 64px);
  padding: 0 !important;
}

@media screen and (min-width: 1200px) {
  .content-scroll {
    height: calc(100vh - 91px);
  }
  .list-message {
    height: calc(100vh - 213px) !important;
  }
}

.message-pc-body /deep/ .footer {
  z-index: 1;
}

#message-pc {
  .ms-layout {
    padding-top: 26px;
    // padding-bottom: 64px;
    // background-color: #f5f5f5;
    background-color: #fff;
    // min-height: 100vh;
    width: 402px;
    border-right: 1px solid #c9c9c9;
  }

  .ms-detail {
    flex-grow: 1;
    width: 678px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
  }

  .list-message {
    padding: 0px 20px;
    padding-bottom: 20px;
    height: calc(100vh - 183px);
    overflow-y: auto;
    overflow-x: hidden;

    .message-item-cover {
      &:not(:last-child) {
        margin-bottom: 10px;
        margin-top: 10px;
      }
      :last-child {
        margin-top: 0;
      }
    }

    .message-item-cover:last-child {
      margin-bottom: 35px;
    }
  }
  .filter-message {
    padding: 15px 20px;
  }
  .no-member-pc {
    margin-left: 34px;
    margin-right: 32px;
    position: absolute;
    .no-member-msg-pc {
      font-family: "Hiragino Kaku Gothic Pro";
      //position: relative;
      float: left;
      img {
        border-radius: 50%;
      }
      h3 {
        position: relative;
        display: inline-flex;
        margin-left: 12px;
        font-weight: 600;
        font-size: 14px;
        top: 2px;
        color: #000000;
      }
    }
  }
}
</style>
