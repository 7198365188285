<template>
  <div
    class="message-item message-item--pc"
    :class="{
      'overflow-hidden': !propsIsPC
    }"
  >
    <div
      class="message-item__content d-flex align-items-center justify-content-between"
    >
      <router-link
        :to="{ name: 'MessageDetail', params: { id: data.id } }"
        class="w-100"
      >
        <div class="w-100 d-flex justify-content-between">
          <div
            class="d-flex align-items-center msg__short"
            v-if="data.members && data.members[0]"
          >
            <div
              class="message-item__image"
              v-if="data.type === 3 && data.owner"
              :style="{ width: imageSize, height: imageSize }"
            >
              <AvatarBox
                :imgUrl="
                  data.owner.imageUrl ||
                    require('@/assets/image/default_user.png')
                "
                :imgSize="propsIsPC ? '48px' : '42px'"
                :imgAlt="data.owner.nickname"
              />
              <AvatarBox
                :imgUrl="data.members[0].imageUrl"
                :imgSize="propsIsPC ? '48px' : '42px'"
                :imgAlt="data.members[0].nickname"
              />
            </div>
            <div
              class="message-item__image"
              v-else
              :style="{ width: imageSize, height: imageSize }"
            >
              <AvatarBox
                :imgUrl="
                  data.members[0].imageUrl ||
                    require('@/assets/image/default_user.png')
                "
                :imgSize="propsIsPC ? '65px' : '58px'"
                :imgAlt="data.members[0].nickname"
              />
            </div>
            <div
              class="d-flex justify-content-between align-items-center _info"
            >
              <div class="d-flex flex-column justify-content-center">
                <h3 v-if="data.type === 3" class="f-w6 _user text-left">
                  {{ formatText(data.roomName.join(", "), 12) }}
                </h3>
                <h3
                  v-if="data.type !== 3 && data.members[0]"
                  class="f-w6 _user text-left"
                >
                  {{ data.members[0].nickname }}
                </h3>
                <span class="f-w3 _nearly">
                  {{ formatText(data.message.message, 10, data.notify) }}
                </span>
              </div>
              <div
                class="d-flex flex-column justify-content-between align-items-end _info__time"
              >
                <p class="_time f-w3">
                  {{
                    data.message
                      ? formatDateTime(data.message.createdAt)
                      : formatDateTime(data.lastSentDateTime)
                  }}
                </p>
                <span class="_count f-w3 fs-10" v-if="data.notify !== 0">{{
                  data.notify
                }}</span>
              </div>
            </div>
          </div>
          <!-- <div class="d-flex justify-content-center align-items-center">
            <div
              class="d-flex flex-column justify-content-between align-items-end"
            >
              <p class="_time f-w3">
                {{
                  data.message !== ""
                    ? formatDateTime(data.message.createdAt)
                    : formatDateTime(data.lastSentDateTime)
                }}
              </p>
              <span class="_count f-w3 fs-10" v-if="data.notify !== 0">{{
                data.notify
              }}</span>
            </div>
          </div> -->
        </div>
      </router-link>
      <div
        class="message-item__hidden position-relative"
        v-if="propsIsPC && data"
      >
        <div class="btn-more" @click="isShow = !isShow">
          <img
            :src="require('@/assets/image/three-dots.svg')"
            alt="three-dots"
          />
        </div>
        <button
          class="position-absolute d-flex justify-content-center align-items-center tab-hide"
          v-if="isShow"
        >
          <img
            :src="require('@/assets/image/hidden-icon-pc.svg')"
            class="hide-icon"
            @click="isShow = !isShow"
          />
          <span class="f-w3 fs-16" @click="hideRoom(data.id)">非表示</span>
        </button>
      </div>
    </div>

    <div class="message-item__button d-flex">
      <!-- <button
        class="btn btn-pin d-flex flex-column justify-content-center align-items-center"
      >
        <img :src="require('@/assets/image/pin.svg')" alt="Pin message" />
        <span>ピン留め</span>
      </button> -->
      <button
        class="btn btn-remove d-flex flex-column justify-content-center align-items-center"
        @click="hideRoom(data.id)"
      >
        <img
          :src="require('@/assets/image/hidden-icon-sp.svg')"
          alt="Remove message"
        />
        <span>非表示</span>
      </button>
    </div>
  </div>
</template>

<script>
import AvatarBox from "../Common/AvatarBox.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MessageListItem",
  components: {
    AvatarBox
  },
  data() {
    return {
      isShow: false
    };
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    propsIsPC: {
      type: Boolean,
      default: false
    },
    imageSize: {
      type: String,
      required: true
    }
  },
  mounted: function() {
    let i,
      iY,
      j,
      jY,
      a,
      k = 0;
    let elements = document.getElementsByClassName("message-item");
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener("touchstart", swipe);
      elements[i].addEventListener("touchend", swipe);
      elements[i].addEventListener("touchmove", swipe);
    }

    function swipe(event) {
      let widthButton = this.getElementsByClassName("message-item__button")[0]
        .offsetWidth;
      if (event.type == "touchstart") {
        i = event.touches[0].clientX;
        iY = event.touches[0].clientY;
        // let trans = this.querySelector(".message-item__content").style
        //   .transform;
        // if (trans === "translateX(0)" || trans === "") {
        //   this.querySelector(".message-item__button").style.opacity = 0;
        // }
      }
      if (event.type == "touchmove") {
        j = event.touches[0].clientX;
        jY = event.touches[0].clientY;
        a = k + Number(j) - Number(i);
        if (a < 0) {
          a = a < -widthButton ? -widthButton : a;
        } else {
          a = 0;
        }
        if (j + 30 < i && Math.abs(jY - iY) < 65) {
          // dich chuyen khi vuot phai qua trai
          this.querySelector(".message-item__content").style.transform =
            "translateX(-78px)";
          this.querySelector(".message-item__button").style.opacity = 1;
        } else {
          this.querySelector(".message-item__content").style.transform =
            "translateX(0)";
          this.querySelector(".message-item__button").style.opacity = 0;
        }
      }
      if (event.type == "touchend") {
        let widthInfo = parseInt(
          this.querySelector(".message-item__content").style.left.replace(
            "px",
            ""
          )
        );
        // this.querySelector(".message-item__button").classList.add("show");

        if (Math.abs(a) < widthButton && widthInfo < 0) {
          this.querySelector(".message-item__content").style.transform =
            "translateX(0)";
          // this.querySelector(".message-item__button").classList.remove("show");
        }
        k = a;
        for (let i = 0; i < elements.length; i++) {
          if (!this.isSameNode(elements[i])) {
            elements[i].querySelector(
              ".message-item__content"
            ).style.transform = "translateX(0)";
            // elements[i].querySelector(
            //   ".message-item__button"
            // ).style.opacity = 0;
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user"
    })
  },
  methods: {
    ...mapActions("firebase", ["setRoomId"]),

    formatText(text, numberLetter, countNotify = 0) {
      if (text) {
        text = text.replace(/(<[^>]+>|<[^>]>|<\/[^>]>)/g, " ");
        if (text.length > numberLetter) {
          return text.substring(0, numberLetter) + "...";
        } else {
          return text;
        }
      } else {
        if (countNotify !== 0) {
          let subText = "新着メッセージがあります";
          return subText.substring(0, 10) + "...";
        }
      }
    },
    formatDateTime(data) {
      if (data) {
        data = data.toDate();
        let dateNow = new Date();
        let time = "";
        if (
          data.getFullYear() === dateNow.getFullYear() &&
          data.getMonth() === dateNow.getMonth() &&
          data.getDate() === dateNow.getDate()
        ) {
          let hour =
            data.getHours() < 10 ? "0" + data.getHours() : data.getHours();
          let minute =
            data.getMinutes() < 10
              ? "0" + data.getMinutes()
              : data.getMinutes();
          time += hour + ":" + minute;
        } else {
          if (data.getFullYear() !== dateNow.getFullYear()) {
            let year = data.getFullYear();
            time += year + "/";
          }
          let day = data.getDate() < 10 ? "0" + data.getDate() : data.getDate();
          let month =
            data.getMonth() + 1 < 10
              ? "0" + (data.getMonth() + 1)
              : data.getMonth() + 1;
          time += month + "/" + day + " ";
        }

        return time;
      }
    },
    async hideRoom(roomId) {
      let data = {
        roomId: roomId,
        userId: this.authUser.user_id
      };
      await this.$store
        .dispatch("firebase/hideRoom", data)
        .then(() => {
          if (this.$route.params.id === roomId) {
            this.$router.push({ name: "Messages" });
            this.setRoomId(null);
          }
          let elements = document.getElementsByClassName("message-item");
          for (let i = 0; i < elements.length; i++) {
            elements[i].querySelector(
              ".message-item__content"
            ).style.transform = "translateX(0)";
            elements[i].querySelector(
              ".message-item__button"
            ).style.opacity = 0;
          }
          this.isShow = false;
        })
        .catch(error => {
          throw error;
        });
    },
    handleRedirect() {
      if (window.innerWidth < 1080) {
        this.$router.push({
          name: "MessageDetail",
          params: { id: this.data.id }
        });
      } else {
        this.$emit("emitChangeRoom", this.data.id);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_fontFamily.scss";
@import "../../assets/scss/mixin.scss";

.message-item {
  position: relative;

  &--active {
    background-color: #ffffff;
    box-shadow: #0000000f 0px 3px 6px;
    border-radius: 5px;
    .message-item__content {
      // background-color: #ffffff;
      background-color: #f5f5f5;
    }
  }

  &__content {
    // background-color: #f5f5f5;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    transition: ease 0.5s;
    padding: 10px 0px;

    a {
      display: block;
      &:hover {
        text-decoration: none;
      }

      .msg__short {
        flex-grow: 1;
      }

      ._info {
        flex-grow: 1;
        p {
          margin-bottom: 8px;
        }

        &__time {
          min-height: 44px;
        }
      }
    }

    @media screen and (min-width: 1200px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__hidden {
    margin-left: 20px;
    .btn-more {
      padding-left: 5px;
      padding-right: 5px;
    }
    .tab-hide {
      background-color: #ffffff;
      border-radius: 5px;
      box-shadow: #00000029 0px 0px 15px;
      right: 0px;
      width: 113px;
      height: 50px;
    }
    .hide-icon {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }

  &__image {
    margin-right: 11px;
    position: relative;

    .avatar-box {
      border: 2px solid #fff;
      &:nth-child(1) {
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &:nth-child(2) {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  h3,
  p,
  span {
    color: #000000;
  }

  h3 {
    margin-bottom: 0;
    margin-bottom: 5px;
  }

  ._user,
  ._nearly {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
  }

  ._nearly {
    color: #6e6e6e;
    text-align: left;
  }

  ._time,
  ._count {
    font-size: 10px;
  }

  ._time {
    @media screen and (min-width: 1200px) {
      font-size: 12px;
    }
    color: #6e6e6e;
  }

  ._count {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #eb5050;
    color: #ffffff;
    @include flexCenter();
  }

  &__button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    // opacity: 0;
    transition: ease 0.5s;

    &.show {
      opacity: 1;
    }

    .btn {
      padding: 10px 11px;
      border-radius: 5px;
      min-width: 58px;

      img {
        width: 18px;
        margin-bottom: 10px;
      }

      span {
        color: #fff;
        font-size: 12px;
      }

      &.btn-pin {
        background-color: #000000;
        margin-right: 2px;
      }

      &.btn-remove {
        line-height: 15px;
        background-color: #f14e4e;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .message-item:hover {
    background-color: #ffffff;
    box-shadow: #0000000f 0px 3px 6px;
    border-radius: 5px;
  }
  .message-item:hover .message-item__content {
    // background-color: #ffffff;
    background-color: #f5f5f5;
  }
}
</style>
