<template>
  <div class="message-filter">
    <div
      v-for="(item, key) in filters"
      :key="key"
      class="_option"
      :class="
        JSON.stringify(filterSelected) == JSON.stringify(item.id)
          ? 'active'
          : ''
      "
      @click="changeFilter(item.id)"
    >
      <button class="f-w6">{{ item.value }}</button>
    </div>
  </div>
</template>

<script>
import {
  SYSTEM_ROOM_TYPE,
  INDIVIDUAL_ROOM_TYPE,
  GROUP_ROOM_TYPE
} from "@/constants/message";
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "MessageFilter",
  data() {
    return {
      filterSelected: [SYSTEM_ROOM_TYPE, INDIVIDUAL_ROOM_TYPE, GROUP_ROOM_TYPE],
      filters: [
        {
          id: [SYSTEM_ROOM_TYPE, INDIVIDUAL_ROOM_TYPE, GROUP_ROOM_TYPE],
          value: "全体"
        },
        {
          id: [INDIVIDUAL_ROOM_TYPE],
          value: ""
        },
        {
          id: [GROUP_ROOM_TYPE],
          value: "待ち合せ"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user"
    })
  },
  props: {
    propsType: {
      type: Array,
      default: function() {
        return [SYSTEM_ROOM_TYPE, INDIVIDUAL_ROOM_TYPE, GROUP_ROOM_TYPE];
      }
    }
  },
  methods: {
    changeFilter(key) {
      this.filterSelected = key;
      this.$emit("emitType", this.filterSelected);
    }
  },
  created() {
    if (this.authUser.sex === 2) {
      Vue.set(this.filters, 1, {
        id: [INDIVIDUAL_ROOM_TYPE],
        value: "ゲスト"
      });
    }
    if (this.authUser.sex === 1) {
      Vue.set(this.filters, 1, {
        id: [INDIVIDUAL_ROOM_TYPE],
        value: "キャスト"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.message-filter {
  display: flex;
  background-color: #e8e8e8;
  width: calc(100% - 38px);
  margin: 0 auto;
  border-radius: 16px;

  ._option {
    display: flex;
    justify-content: center;
    flex: 1;
    position: relative;
    cursor: pointer;

    button {
      color: #9f9f9f;
      font-weight: bold;
      height: 32px;
      border-radius: 21px;
      min-width: 108px;
      transition: ease 0.3s;
      font-size: 14px;
      background-color: transparent;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    @media screen and (min-width: 1200px) {
      button {
        height: 36px;
      }
    }

    &.active {
      button {
        width: 100%;
        color: #ffffff;
        background-color: #464d77;
      }
    }
  }
}
</style>
